.reset-form, .pagination-btn {
  cursor: pointer;
}

.dropdown-box {
  overflow: visible;
}
.filter-bar-form {
  width: fit-content;
}
.form_width {
  width: calc(100vw - 256px);
}