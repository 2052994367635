.thumbsContainer {
  display: flex;
  position: relative;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
};

.thumb {
  display: inline-flex;
  border-radius: 2px;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100%;
  padding: 4px;
  boxSizing: border-box;
  height: 100%;
};

.thumbInner {
  display: flex;
  width: 100%;
  height: 100%;
  min-width: 0;
  overflow: hidden;
  margin: 0 auto;
};

.img {
  display: block;
  width: 100%;
  height: auto;
};
