.spinner {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 3px solid #222;
  border-radius: 50%;
  border-top-color: transparent;
  animation: spin .8s ease-in-out infinite;
  -webkit-animation: spin .8s ease-in-out infinite;
}

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}