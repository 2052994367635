@import "~tailwindcss/tailwind.css";
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

input, button {
  outline: none;
}
.codex-editor--narrow .codex-editor__redactor {
  margin-right: 2rem;
}
.codex-editor__redactor{
  padding-bottom: 150px !important;
}
.codex-editor--narrow .link-tool__image{
  display: block !important;
}
.ce-block__content{
  margin: 0;
  max-width: 100%;
}
.ce-block b {
  font-weight: 500;
}
.DayPickerInput, .DayPickerInput input {
  width: 100%;
}
.DayPickerInput input{
  font-size: .875rem;
  line-height: 1.25rem;
  --tw-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  padding: 0.5rem 0.75rem;
  --tw-border-opacity: 1;
  border: 1px solid rgba(209,213,219,var(--tw-border-opacity));
  border-radius: 7px;
  margin-bottom: 5px;
}

/*quote*/
.cdx-input.cdx-quote__caption {
  display: none;
}
.cdx-block.cdx-quote {
  border-radius: 7px;
}
.cdx-input.cdx-quote__text {
  background-color: #ECF6FF;
  border: none;
  box-shadow: none;
  padding: 20px 25px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  border-left: 3px solid #0A85FF;
  margin-bottom: 0;
  min-height: 0;
}
.cdx-input.cdx-warning__title {
  padding: 10px 44px !important;
}

.cdx-warning::before {
  left: 12px !important;
}

h1 {
  display: block;
  font-size: 2em !important;
  font-weight: bold !important;
}
h2 {
  display: block;
  font-size: 1.5em !important;
  font-weight: bold !important;
}
h3 {
  display: block;
  font-size: 1.17em !important;
  font-weight: bold !important;
}
h4 {
  display: block;
  font-size: 1em !important;
  font-weight: bold !important;
}
h5 {
  display: block;
  font-size: .83em !important;
  font-weight: bold !important;
}
h6 {
  display: block;
  font-size: .67em !important;
  font-weight: bold !important;
}
.post_container {
  max-width: 1200px;
}

textarea.post_title {
  min-height: 115px;
}

textarea.meta_description {
  min-height: 150px;
}