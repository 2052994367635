.ce-inline-tool-hyperlink-wrapper {
    outline: none;
    border: 0;
    border-radius: 0 0 4px 4px;
    margin: 0;
    font-size: 13px;
    padding: 10px;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: none;
    font-weight: 500;
    border-top: 1px solid rgba(201,201,204,.48);
}

.ce-inline-tool-hyperlink-wrapper.ce-inline-tool-hyperlink-wrapper--showed {
    display: block;
}

.ce-inline-tool-hyperlink--input,
.ce-inline-tool-hyperlink--select-target,
.ce-inline-tool-hyperlink--select-rel {
    border: 1px solid rgba(201,201,204,.48);
    -webkit-box-shadow: inset 0 1px 2px 0 rgba(35,44,72,.06);
    box-shadow: inset 0 1px 2px 0 rgba(35,44,72,.06);
    border-radius: 5px;
    padding: 5px 8px;
    margin-bottom: 10px;
    outline: none;
    width: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.ce-inline-tool-hyperlink--select-target,
.ce-inline-tool-hyperlink--select-rel {
    width: 48%;
    display: inline-block;
}
.ce-inline-tool-hyperlink--select-target {
    margin-right: 2%;
}
.ce-inline-tool-hyperlink--select-rel {
    margin-left: 2%;
}

.ce-inline-tool-hyperlink--button {
    display: block;
    width: 100%;
    background-color: #34c38f;
    color: #fff;
    padding: 7px 0;
    border: none;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}